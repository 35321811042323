export default [
    { id: 10000002, key: 'Ayaka', name: 3752, weapon: 'SWORD_ONE_HAND', quailty: 'ORANGE' },
    { id: 10000003, key: 'Qin', name: 3753, weapon: 'SWORD_ONE_HAND', quailty: 'ORANGE' },
    { id: 10000005, key: 'PlayerBoy', name: 3754, weapon: 'SWORD_ONE_HAND', quailty: 'ORANGE' },
    { id: 10000006, key: 'Lisa', name: 3755, weapon: 'CATALYST', quailty: 'PURPLE' },
    { id: 10000007, key: 'PlayerGirl', name: 3756, weapon: 'SWORD_ONE_HAND', quailty: 'ORANGE' },
    { id: 10000014, key: 'Barbara', name: 3757, weapon: 'CATALYST', quailty: 'PURPLE' },
    { id: 10000015, key: 'Kaeya', name: 3758, weapon: 'SWORD_ONE_HAND', quailty: 'PURPLE' },
    { id: 10000016, key: 'Diluc', name: 3759, weapon: 'CLAYMORE', quailty: 'ORANGE' },
    { id: 10000020, key: 'Razor', name: 3760, weapon: 'CLAYMORE', quailty: 'PURPLE' },
    { id: 10000021, key: 'Ambor', name: 3761, weapon: 'BOW', quailty: 'PURPLE' },
    { id: 10000022, key: 'Venti', name: 3762, weapon: 'BOW', quailty: 'ORANGE' },
    { id: 10000023, key: 'Xiangling', name: 3763, weapon: 'POLE', quailty: 'PURPLE' },
    { id: 10000024, key: 'Beidou', name: 3764, weapon: 'CLAYMORE', quailty: 'PURPLE' },
    { id: 10000025, key: 'Xingqiu', name: 3765, weapon: 'SWORD_ONE_HAND', quailty: 'PURPLE' },
    { id: 10000026, key: 'Xiao', name: 3766, weapon: 'POLE', quailty: 'ORANGE' },
    { id: 10000027, key: 'Ningguang', name: 3767, weapon: 'CATALYST', quailty: 'PURPLE' },
    { id: 10000029, key: 'Klee', name: 3768, weapon: 'CATALYST', quailty: 'ORANGE' },
    { id: 10000030, key: 'Zhongli', name: 3769, weapon: 'POLE', quailty: 'ORANGE' },
    { id: 10000031, key: 'Fischl', name: 3770, weapon: 'BOW', quailty: 'PURPLE' },
    { id: 10000032, key: 'Bennett', name: 3771, weapon: 'SWORD_ONE_HAND', quailty: 'PURPLE' },
    { id: 10000033, key: 'Tartaglia', name: 3772, weapon: 'BOW', quailty: 'ORANGE' },
    { id: 10000034, key: 'Noel', name: 3773, weapon: 'CLAYMORE', quailty: 'PURPLE' },
    { id: 10000035, key: 'Qiqi', name: 3774, weapon: 'SWORD_ONE_HAND', quailty: 'ORANGE' },
    { id: 10000036, key: 'Chongyun', name: 3775, weapon: 'CLAYMORE', quailty: 'PURPLE' },
    { id: 10000037, key: 'Ganyu', name: 3776, weapon: 'BOW', quailty: 'ORANGE' },
    { id: 10000038, key: 'Albedo', name: 3777, weapon: 'SWORD_ONE_HAND', quailty: 'ORANGE' },
    { id: 10000039, key: 'Diona', name: 3778, weapon: 'BOW', quailty: 'PURPLE' },
    { id: 10000041, key: 'Mona', name: 3779, weapon: 'CATALYST', quailty: 'ORANGE' },
    { id: 10000042, key: 'Keqing', name: 3780, weapon: 'SWORD_ONE_HAND', quailty: 'ORANGE' },
    { id: 10000043, key: 'Sucrose', name: 3781, weapon: 'CATALYST', quailty: 'PURPLE' },
    { id: 10000044, key: 'Xinyan', name: 3782, weapon: 'CLAYMORE', quailty: 'PURPLE' },
    { id: 10000045, key: 'Rosaria', name: 3783, weapon: 'POLE', quailty: 'PURPLE' },
    { id: 10000046, key: 'Hutao', name: 3784, weapon: 'POLE', quailty: 'ORANGE' },
    { id: 10000047, key: 'Kazuha', name: 3785, weapon: 'SWORD_ONE_HAND', quailty: 'ORANGE' },
    { id: 10000048, key: 'Feiyan', name: 3786, weapon: 'CATALYST', quailty: 'PURPLE' },
    { id: 10000049, key: 'Yoimiya', name: 3787, weapon: 'BOW', quailty: 'ORANGE' },
    { id: 10000050, key: 'Tohma', name: 3788, weapon: 'POLE', quailty: 'PURPLE' },
    { id: 10000051, key: 'Eula', name: 3789, weapon: 'CLAYMORE', quailty: 'ORANGE' },
    { id: 10000052, key: 'Shougun', name: 3790, weapon: 'POLE', quailty: 'ORANGE' },
    { id: 10000053, key: 'Sayu', name: 3791, weapon: 'CLAYMORE', quailty: 'PURPLE' },
    { id: 10000054, key: 'Kokomi', name: 3792, weapon: 'CATALYST', quailty: 'ORANGE' },
    { id: 10000055, key: 'Gorou', name: 3793, weapon: 'BOW', quailty: 'PURPLE' },
    { id: 10000056, key: 'Sara', name: 3794, weapon: 'BOW', quailty: 'PURPLE' },
    { id: 10000057, key: 'Itto', name: 3795, weapon: 'CLAYMORE', quailty: 'ORANGE' },
    { id: 10000058, key: 'Yae', name: 3796, weapon: 'CATALYST', quailty: 'ORANGE' },
    { id: 10000059, key: 'Heizo', name: 3797, weapon: 'CATALYST', quailty: 'PURPLE' },
    { id: 10000060, key: 'Yelan', name: 3798, weapon: 'BOW', quailty: 'ORANGE' },
    { id: 10000061, key: 'Momoka', name: 3799, weapon: 'SWORD_ONE_HAND', quailty: 'PURPLE' },
    { id: 10000062, key: 'Aloy', name: 3800, weapon: 'BOW', quailty: 'ORANGE_SP' },
    { id: 10000063, key: 'Shenhe', name: 3801, weapon: 'POLE', quailty: 'ORANGE' },
    { id: 10000064, key: 'Yunjin', name: 3802, weapon: 'POLE', quailty: 'PURPLE' },
    { id: 10000065, key: 'Shinobu', name: 3803, weapon: 'SWORD_ONE_HAND', quailty: 'PURPLE' },
    { id: 10000066, key: 'Ayato', name: 3804, weapon: 'SWORD_ONE_HAND', quailty: 'ORANGE' },
    { id: 10000067, key: 'Collei', name: 3805, weapon: 'BOW', quailty: 'PURPLE' },
    { id: 10000068, key: 'Dori', name: 3806, weapon: 'CLAYMORE', quailty: 'PURPLE' },
    { id: 10000069, key: 'Tighnari', name: 3807, weapon: 'BOW', quailty: 'ORANGE' },
    { id: 10000070, key: 'Nilou', name: 3808, weapon: 'SWORD_ONE_HAND', quailty: 'ORANGE' },
    { id: 10000071, key: 'Cyno', name: 3809, weapon: 'POLE', quailty: 'ORANGE' },
    { id: 10000072, key: 'Candace', name: 3810, weapon: 'POLE', quailty: 'PURPLE' },
    { id: 10000073, key: 'Nahida', name: 3811, weapon: 'CATALYST', quailty: 'ORANGE' },
    { id: 10000074, key: 'Layla', name: 3812, weapon: 'SWORD_ONE_HAND', quailty: 'PURPLE' },
    { id: 10000075, key: 'Wanderer', name: 3813, weapon: 'CATALYST', quailty: 'ORANGE' },
    { id: 10000076, key: 'Faruzan', name: 3814, weapon: 'BOW', quailty: 'PURPLE' },
    { id: 10000077, key: 'Yaoyao', name: 3815, weapon: 'POLE', quailty: 'PURPLE' },
    { id: 10000078, key: 'Alhatham', name: 3816, weapon: 'SWORD_ONE_HAND', quailty: 'ORANGE' },
    { id: 10000079, key: 'Dehya', name: 3817, weapon: 'CLAYMORE', quailty: 'ORANGE' },
    { id: 10000080, key: 'Mika', name: 3818, weapon: 'POLE', quailty: 'PURPLE' },
    { id: 10000081, key: 'Kaveh', name: 3819, weapon: 'CLAYMORE', quailty: 'PURPLE' },
    { id: 10000082, key: 'Baizhuer', name: 3820, weapon: 'CATALYST', quailty: 'ORANGE' },
    { id: 10000083, key: 'Linette', name: 3821, weapon: 'SWORD_ONE_HAND', quailty: 'PURPLE' },
    { id: 10000084, key: 'Liney', name: 3822, weapon: 'BOW', quailty: 'ORANGE' },
    { id: 10000085, key: 'Freminet', name: 3823, weapon: 'CLAYMORE', quailty: 'PURPLE' },
    { id: 10000086, key: 'Wriothesley', name: 3824, weapon: 'CATALYST', quailty: 'ORANGE' },
    { id: 10000087, key: 'Neuvillette', name: 3825, weapon: 'CATALYST', quailty: 'ORANGE' },
    { id: 10000088, key: 'Charlotte', name: 3826, weapon: 'CATALYST', quailty: 'PURPLE' },
    { id: 10000089, key: 'Furina', name: 3827, weapon: 'SWORD_ONE_HAND', quailty: 'ORANGE' },
    { id: 10000090, key: 'Chevreuse', name: 3828, weapon: 'POLE', quailty: 'PURPLE' },
    { id: 10000091, key: 'Navia', name: 3829, weapon: 'CLAYMORE', quailty: 'ORANGE' },
    { id: 10000092, key: 'Gaming', name: 3830, weapon: 'CLAYMORE', quailty: 'PURPLE' },
    { id: 10000093, key: 'Liuyun', name: 3831, weapon: 'CATALYST', quailty: 'ORANGE' },
    { id: 10000094, key: 'Chiori', name: 3832, weapon: 'SWORD_ONE_HAND', quailty: 'ORANGE' },
    { id: 10000095, key: 'Sigewinne', name: 3833, weapon: 'BOW', quailty: 'ORANGE' },
    { id: 10000096, key: 'Arlecchino', name: 3834, weapon: 'POLE', quailty: 'ORANGE' },
    { id: 10000097, key: 'Sethos', name: 3835, weapon: 'BOW', quailty: 'PURPLE' },
    { id: 10000098, key: 'Clorinde', name: 3836, weapon: 'SWORD_ONE_HAND', quailty: 'ORANGE' },
    { id: 10000099, key: 'Emilie', name: 3837, weapon: 'POLE', quailty: 'ORANGE' },
    { id: 10000100, key: 'Kachina', name: 3838, weapon: 'POLE', quailty: 'PURPLE' },
    { id: 10000101, key: 'Kinich', name: 3839, weapon: 'CLAYMORE', quailty: 'ORANGE' },
    { id: 10000102, key: 'Mualani', name: 3840, weapon: 'CATALYST', quailty: 'ORANGE' },
    { id: 10000103, key: 'Xilonen', name: 3841, weapon: 'SWORD_ONE_HAND', quailty: 'ORANGE' },
    { id: 10000104, key: 'Chasca', name: 3842, weapon: 'BOW', quailty: 'ORANGE' },
    { id: 10000105, key: 'Olorun', name: 3843, weapon: 'BOW', quailty: 'PURPLE' },
    { id: 10000106, key: 'Mavuika', name: 3844, weapon: 'CLAYMORE', quailty: 'ORANGE' },
    { id: 10000107, key: 'Citlali', name: 3845, weapon: 'CATALYST', quailty: 'ORANGE' },
    { id: 10000108, key: 'Lanyan', name: 3846, weapon: 'CATALYST', quailty: 'PURPLE' },
    { id: 10000109, key: 'Mizuki', name: 3847, weapon: 'CATALYST', quailty: 'ORANGE' },
    { id: 10000110, key: 'Iansan', name: 3848, weapon: 'POLE', quailty: 'PURPLE' },
    { id: 10000111, key: 'Varesa', name: 3849, weapon: 'CATALYST', quailty: 'ORANGE' },
]
